.stars {
  display: flex;
}

.star {
  width: 1.9em;
  height: 1.9em;
  margin-right: 0.13em;
  background: url('assets/StarGrey.png') no-repeat;
  background-size: cover;
  position: relative;
}

.star-fill {
  height: 100%;
  width: 0%; /* Will be set dynamically */
  position: absolute;
  left: 0;
  background: url('assets/Star.png') no-repeat;
  background-size: cover;
  z-index: 2;
}
