/* Information.css */
.general-information {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 800px;
  margin: 20px auto;
}

.info-title {
  font-size: 32px;
  margin-bottom: 15px;
  color: #333;
}

.info-item {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 10px;
  color: #555;
}

.info-item strong {
  color: #000;
}
