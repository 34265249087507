.dashboardContainer {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.contentArea {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: #f5f5f5;
}

.card {
  margin-bottom: 20px;
}
