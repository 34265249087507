.reviews-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 85%;
}

.rating-section {
  width: 100%;
  padding: 20px;
}

h5 {
  font-size: 2em;
  margin-bottom: 15px;
  font-weight: 500;
}

h6 {
  font-size: 1.6em;
  margin-bottom: 20px;
  font-weight: 500;
}

.rating-breakdown {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  transition: background 0.2s ease;
}

.star-span {
  width: 70px; /* Fixed width for consistency */
  font-size: 1.1em;
  color: mediumgray; /* Default color */
  transition: color 0.2s ease;
}

.count-span {
  width: 30px; /* Assign a fixed width */
  text-align: right;
}

.rating-breakdown:hover .star-span {
  color:  darkgray;
}

.rating-breakdown.selected .star-span {
  color: #00C6B6; /* Turquoise when selected */
}

.bar-background {
  flex: 1;
  background: #e0e0e0;
  height: 20px;
  margin: 10px 0px;
  border-radius: 5px;
  width: 150px;
}

.bar-fill {
  height: 100%;
  background: #464646; /* Medium-dark grey as default */
  border-radius: 5px;
  transition: background 0.2s ease;
}

.rating-breakdown.selected .bar-fill {
  background: #00C6B6; /* Turquoise when selected */
}